<template>
  <div class="lesseePermissions">
    <div class="topContainer">
      <div class="div-menu">
        <a-menu style="width: 100%" mode="vertical" @click="handleClick" :selectedKeys="[selectedPlatformId]">
          <a-menu-item v-for="item in platformOption" :key="item.platformId">
            {{ item.platformName }}
          </a-menu-item>
        </a-menu>
      </div>
      <div class="divContent">
        <div class="select-all">
          <a-checkbox @change="selectedAll" v-model="isSelectedAll" :disabled="showType === '2' && !isEdit">
            全选
          </a-checkbox>
        </div>
        <a-tree
          v-model="form.menuIds"
          checkable
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @select="onSelect"
          @check="onCheck"
          :replaceFields="{
            children: 'children',
            title: 'menuName',
            key: 'menuId',
          }"
          :disabled="showType === '2' && !isEdit"
        />
      </div>
    </div>
    <a-form-model :rules="rules" :model="form" ref="ruleForm">
      <a-form-model-item label="状态" prop="status">
        <a-select
          v-model="form.status"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          :disabled="showType === '2' && !isEdit"
        >
          <a-select-option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { getPlatformListV2 } from "@/api/common";
import { getMenus } from "@/api/system/menuManage";
import { REQUEST_CODE } from "@/constant/status";
export default {
  name: "LesseePermissions",
  props: {
    lesseeInfo: {
      type: Object,
      default: null,
    },
    showType: {
      type: String,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        menuIds: [],
        status: undefined,
      },
      rules: {
        status: { required: true, message: "请输入", trigger: "change" },
      },
      //平台选项
      platformOption: [],
      //选中平台
      selectedPlatformId: "",

      //tree数据
      expandedKeys: [],
      autoExpandParent: true,
      selectedKeys: [],
      treeData: [],

      //状态选项
      options: [
        {
          id: "1",
          value: "启用",
        },
        {
          id: "2",
          value: "禁用",
        },
      ],
      //记录权限数据
      authDataRecord: {},
      //所有菜单
      allMenu: [],
      //是否选中所有菜单
      isSelectedAll: false,
      //当前权限数据
      userCurrentAuth: [],
    };
  },
  watch: {
    lesseeInfo(newVal) {
      if (newVal) {
        this.form = {
          menuIds: [],
          status: newVal.status ? "2" : "1",
        };
        this.userCurrentAuth = newVal.menuIdList;
        this.getPlatform();
      }
    },
  },
  methods: {
    //选择平台
    handleClick(e) {
      this.selectedPlatformId = e.key;
      this.getMenu();
    },
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys) {
      this.selectedKeys = selectedKeys;
    },
    onCheck(checkedKeys) {
      this.isSelectedAll = this.judgeIsAllSelect(checkedKeys);
      this.updateMenuData(checkedKeys);
    },

    //保存
    save() {
      let menuIds = [];
      Object.keys(this.authDataRecord).forEach(item => {
        menuIds.push(...this.authDataRecord[item].menuIds);
      })
      if (!menuIds.length) {
        this.$message.error("请设置租户权限");
        return { verify: false };
      }
      let submitData = {};
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          submitData.verify = true;
          submitData.form = {
            menuIds,
            status: this.form.status === "1" ? false : true,
          };
          return true;
        } else {
          this.$message.error("请设置权限状态");
          submitData.verify = false;
          return false;
        }
      });
      return submitData;
    },
    //菜单权限全选
    selectedAll(event) {
      this.isSelectedAll = event.target.checked;
      if (this.isSelectedAll) {
        this.allMenu = [];
        this.selectedCurrentPlatFormAllMenu(this.treeData);
      } else {
        this.allMenu = [];
      }
      this.updateMenuData(this.allMenu);
      this.getShowMenu();
    },
    //选中当前平台所有菜单
    selectedCurrentPlatFormAllMenu(menuList) {
      menuList.forEach((item) => {
        if (item.children && item.children.length) {
          this.allMenu.push(item.menuId);
          this.selectedCurrentPlatFormAllMenu(item.children);
        } else {
          this.allMenu.push(item.menuId);
        }
      });
    },
    //判断是否是全选
    judgeIsAllSelect(currentData) {
      let isAll = true;
      if (this.allMenu.length) {
        this.allMenu.forEach((item) => {
          if (currentData.indexOf(item) === -1) {
            isAll = false;
          }
        });
      } else {
        isAll = false;
      }

      return isAll;
    },

    /**网络请求 */
    //获取平台列表
    async getPlatform() {
      try {
        const result = await getPlatformListV2();
        if (result.code === REQUEST_CODE) {
          this.platformOption = result.data;
          this.selectedPlatformId = this.platformOption[0].platformId;
          this.getMenu();
          this.getAllPlatformList();
        }
      } catch (error) {}
    },
    //获取所有平台列表
    async getAllPlatformList() {
      let promiseArr = [];
      this.platformOption.forEach((item) => {
        promiseArr.push(getMenus(item.platformId, 1, 999));
        this.authDataRecord[item.platformKey] = {
          platformId: item.platformId,
          menuIds: [],
        };
      });
      const result = await Promise.all(promiseArr);
      result.forEach((item) => {
        this.matchingPlatformMenu(item.data.menuList);
      });
      this.getShowMenu();
    },
    //获取菜单列表
    async getMenu() {
      try {
        const result = await getMenus(this.selectedPlatformId, 1, 999);
        if (result.code === "200") {
          this.treeData = result.data.menuList;
          this.allMenu = [];
          this.selectedCurrentPlatFormAllMenu(this.treeData);
          this.getShowMenu();
        }
      } catch (error) {}
    },
    //递归匹配平台菜单
    matchingPlatformMenu(menuList) {
      if (menuList && menuList.length) {
        let platform = this.matchingPlatform(menuList[0].platformId);
        menuList.forEach((item) => {
          if (this.userCurrentAuth.indexOf(item.menuId) !== -1) {
            platform.menuIds.push(item.menuId);
          }
          if (item.children && item.children.length) {
            this.matchingPlatformMenu(item.children);
          }
        });
      }
    },
    //匹配平台
    matchingPlatform(platformId) {
      let matchingPlatform = undefined;
      for (let key in this.authDataRecord) {
        if (this.authDataRecord[key].platformId === platformId) {
          matchingPlatform = this.authDataRecord[key];
          break;
        }
      }
      return matchingPlatform;
    },
    //获取显示菜单列表
    getShowMenu() {
      const platform = this.matchingPlatform(this.selectedPlatformId);
      this.form.menuIds = platform.menuIds;
      this.isSelectedAll = this.judgeIsAllSelect(this.form.menuIds);
    },
    //更新菜单数据
    updateMenuData(menuIds) {
      const platform = this.matchingPlatform(this.selectedPlatformId);
      platform.menuIds = menuIds;
    },
  },
  created() {
    if (!this.isEdit) {
      this.getPlatform();
    }
  },
};
</script>

<style lang="scss" scoped>
.lesseePermissions {
  .topContainer {
    overflow: hidden;
  }
}
.div-menu {
  width: 25%;
  height: 100%;
  float: left;
  .elCol {
    width: 100%;
    text-align: center;
    .elMenu {
      background: rgb(247, 248, 252);
      height: 100%;
      .elMenuItem {
        span {
          width: 100%;
        }
      }
    }
  }
}
.divContent {
  width: 75%;
  float: right;
}
::v-deep .ant-menu-vertical .ant-menu-item {
  // font-size: 20px;
  color: #000;
  font-weight: 700;
}
.select-all {
  padding-left: 26px;
  padding-top: 5px;
}
</style>
