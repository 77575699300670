<template>
  <div class="addLesseeBaseInfo">
    <ds-header :title="`租户管理 / ${headerTitle}`"></ds-header>
    <div class="editBtn" v-if="showType !== '0'">
      <a-button type="primary" @click="edit"> {{ isEdit ? "取消编辑" : "编辑" }} </a-button>
    </div>
    <div v-if="showType !== '0'">
      <a-menu v-model="current" mode="horizontal" @select="selectedInfoType">
        <a-menu-item key="accountInfo"> 账户信息 </a-menu-item>
        <a-menu-item key="authInfo"> 功能权限 </a-menu-item>
      </a-menu>
    </div>
    <LesseeInfo
      ref="lesseeInfo"
      v-show="step === 1"
      :showType="showType"
      :isEdit="isEdit"
      :lesseeInfo="lesseeInfo"
    ></LesseeInfo>
    <LesseePermissions
      ref="lesseePermissions"
      :step="step"
      v-show="step === 2"
      :showType="showType"
      :isEdit="isEdit"
      :lesseeInfo="lesseeInfo"
    ></LesseePermissions>
    <div style="text-align: center">
      <ds-button
        :type="$DSBT.DS_DEFAULT"
        :title="step === 2 ? '保存' : isEdit ? '保存' : '下一步'"
        @click="nextStep"
        v-if="showType !== '2' || isEdit"
      />
      <ds-button
        :type="$DSBT.DS_CANCEL"
        :title="step === 2 ? (showType === '2' ? '返回' : '上一步') : '返回'"
        @click="goBack"
        style="margin-left: 20px"
      />
    </div>
  </div>
</template>

<script>
import LesseeInfo from "../components/lesseeInfo";
import LesseePermissions from "../components/lesseePermissions";
import { REQUEST_CODE } from "@/constant/status";
import * as api from "@/api/authManage/lesseeManage";
import { mapState } from "vuex";
import debounce from "lodash/debounce";
export default {
  name: "AddLesseeBaseInfo",
  components: {
    LesseeInfo,
    LesseePermissions,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
    isDisable() {
      return this.showType === "2" ? true : false;
    },
  },
  data() {
    return {
      //添加步骤, 1.基础数据, 2.权限数据
      step: 1,
      //提交数据
      submitData: undefined,
      //是否编辑
      isEdit: false,
      //显示状态
      showType: "",
      //租户信息
      lesseeInfo: undefined,
      //当前选项
      current: ["accountInfo"],
    };
  },
  methods: {
    //下一步
    nextStep: debounce(function () {
      if (this.step === 1) {
        const tempData = this.$refs.lesseeInfo.nextStep();
        if (tempData.verify) {
          this.submitData = tempData.form;
          //如果是编辑状态直接进行保存请求
          if (this.isEdit) {
            const data = this.$refs.lesseePermissions.save();
            if (data.verify) {
              const form = { ...this.submitData };
              const tenantInfo = {
                companyName: form.companyName,
                tenantType: form.tenantType,
                tenantAddress: form.tenantAddress,
                businessLicense: JSON.stringify(form.businessLicense),
                contacts: form.contacts,
                phone: form.phone,
                email: form.email,
                contactsAddress: form.contactsAddress,
              };
              const submitData = {
                tenantInfo,
                tenantName: form.tenantName,
                username: form.username,
                password: form.password,
                tenantId: form.tenantId,
                ...data.form,
              };
              this.editLesseeInfo(submitData);
            }
          } else {
            this.step = 2;
          }
        }
      } else {
        const data = this.$refs.lesseePermissions.save();
        if (data.verify) {
          if (this.isEdit) {
            const tempData = this.$refs.lesseeInfo.nextStep();
            if (tempData.verify) {
              this.submitData = tempData.form;
            } else {
              return;
            }
          }
          const form = { ...this.submitData };
          const tenantInfo = {
            companyName: form.companyName,
            tenantType: form.tenantType,
            tenantAddress: form.tenantAddress,
            businessLicense: JSON.stringify(form.businessLicense),
            contacts: form.contacts,
            phone: form.phone,
            email: form.email,
            contactsAddress: form.contactsAddress,
          };
          let submitData = null;
          if (this.isEdit) {
            submitData = {
              tenantInfo,
              tenantName: form.tenantName,
              tenantId: this.lesseeInfo.tenantId,
              ...data.form,
            };
            this.editLesseeInfo(submitData);
          } else {
            submitData = {
              tenantInfo,
              tenantName: form.tenantName,
              username: form.username,
              password: form.password,
              ...data.form,
            };
            this.addLessee(submitData);
          }
        }
      }
    }, 500),
    //返回
    goBack() {
      if (this.showType === "2") {
        this.$router.go(-1);
      } else {
        if (this.step === 2) {
          this.step = 1;
        } else {
          this.$router.go(-1);
        }
      }
    },
    //编辑
    edit() {
      if (this.isEdit) {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
    },
    //选择信息类型
    selectedInfoType(data) {
      if (data.key === "accountInfo") {
        this.step = 1;
      } else {
        this.step = 2;
      }
    },

    /**网络请求 */
    //添加租户
    async addLessee(submitData) {
      try {
        const result = await api.addLessee(submitData);
        if (result.code === REQUEST_CODE) {
          this.$message.success("添加成功");
          this.$router.go(-1);
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error(error.msg);
      }
    },
    //查看租户详情
    async getLesseeDetail(tenantId) {
      try {
        const result = await api.getLesseeDetail(tenantId);
        if (result.code === REQUEST_CODE) {
          this.lesseeInfo = result.data;
        }
      } catch (error) {}
    },
    //编辑租户信息
    async editLesseeInfo(submitData) {
      try {
        const result = await api.editLesseeInfo(submitData);
        if (result.code === REQUEST_CODE) {
          this.$message.success("编辑成功");
          this.$router.go(-1);
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error(error.msg);
      }
    },

    //判断header显示文字
    judgeHeaderTitle() {
      if (this.showType === "0") {
        this.headerTitle = "新增租户";
      } else if (this.showType === "1") {
        this.headerTitle = "修改租户";
      } else {
        this.headerTitle = "租户详情";
      }
    },
  },
  created() {
    this.showType = this.$route.query.showType;
    this.judgeHeaderTitle();
    if (this.showType === "2") {
      this.getLesseeDetail(this.$route.query.tenantId);
    }
  },
};
</script>

<style lang="scss" scoped>
.addLesseeBaseInfo {
  position: relative;
  .editBtn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
</style>
