<template>
  <div class="ds-upload">
    <a-upload v-on="$listeners" v-bind="$props">
      <slot></slot>
    </a-upload>
    <span>{{ promptTitle }}</span>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import { Upload } from "ant-design-vue";
export default {
  name: "ds-upload",
  props: {
    ...Upload.props,
    promptTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      IMG_API: IMG_API,
    };
  },
  methods: {
    uploadEnterLogo(data) {
      // this.$emit("uploadEnterLogo", data);
    }
  },
  mounted() {
    
  },
};
</script>

<style lang="scss" scoped>

</style>
