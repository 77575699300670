//校验手机号
export function isvalidPhone(rule, value, callback) {
  const reg = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/;
  
  if (value) {
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error("输入电话号码不正确"));
    }
  }else {
    callback();
  }
  
}
//校验email
export function isValidEmail(rule, value, callback) {
  const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
  if (reg.test(value)) {
    callback();
  }else {
    callback(new Error("输入email格式不正确"));
  }
}

//校验账号不能为纯数字
export function isValidAccountNoAllNumber(rule, value, callback) {
  var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])');
  if (pwdRegex.test(value)) {
    callback();
  }else {
    callback(new Error("输入账号必须包含字母、数字"));
  }
}
/**
* @description 校验用户密码验证--需包含字母、数字、特殊字符，其中任意两种
* @author cxd
* @param 
* @time 2022-03-04 17:41:23
*/
export function isValidPassword(rule, value, callback) {
  let pwdRegex = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]/;
  if (pwdRegex.test(value)) {
    callback();
  }else {
    callback(new Error("请根据规则输入密码"));
  }
}