<template>
  <div class="lesseeInfo">
    <a-form-model layout="inline" :rules="rules" :model="form" ref="ruleForm">
      <div class="formTitle">主账户信息</div>
      <a-form-model-item
        label="租户名称："
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 23, offset: 1 }"
        prop="tenantName"
      >
        <a-input
          :value="form.tenantName"
          @input="(e) => (form.tenantName = forbiddenSpecialCharsEmoji(e))"
          placeholder="请输入租户名称"
          :disabled="showType !== '0'"
        />
      </a-form-model-item>
      <a-form-model-item
        label="租户账号："
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 23, offset: 1 }"
        prop="username"
      >
        <a-input
          :value="form.username"
          @input="(e) => (form.username = validAllowCharNum(e))"
          placeholder="请输入租户账号"
          :disabled="showType !== '0'"
          autocomplete="new-password"
        />
      </a-form-model-item>
      <a-form-model-item
        label="初始密码："
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 23, offset: 1 }"
        :prop="showType === '0' ? 'password' : ''"
      >
        <a-input-password
          :value="form.password"
          @input="(e) => (form.password = validAllowCharNum(e))"
          placeholder="请输入初始密码"
          :disabled="showType !== '0'"
          autocomplete="new-password"
        />
      </a-form-model-item>
      <div class="formTitle">租户信息</div>
      <a-form-model-item
        label="所属公司："
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 23, offset: 1 }"
        prop="companyName"
      >
        <a-input
          :value="form.companyName"
          @input="(e) => (form.companyName = forbidEmoji(e))"
          placeholder="请输入所属公司"
          :disabled="showType === '2' && !isEdit"
        />
      </a-form-model-item>
      <a-form-model-item
        label="租户类型："
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 23, offset: 1 }"
        prop="tenantType"
      >
        <a-select
          v-model="form.tenantType"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :disabled="showType === '2' && !isEdit"
        >
          <a-select-option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="租户地址："
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 23, offset: 1 }"
        prop="tenantAddress"
      >
        <a-input
          :value="form.tenantAddress"
          @input="(e) => (form.tenantAddress = forbidEmoji(e))"
          placeholder="请输入租户地址"
          :disabled="showType === '2' && !isEdit"
        />
      </a-form-model-item>
      <a-row type="flex" style="margin-top: 20px">
        <a-form-model-item label="营业执照：" prop="businessLicense" :span="24" style="width: 100%">
          <a-upload
            :action="IMG_API + '/oss/files'"
            list-type="picture-card"
            :file-list="form.businessLicense"
            @preview="handlePreview"
            @change="handleChange"
            :before-upload="beforeUpload"
            style="margin-top: 10px"
            :disabled="showType === '2' && !isEdit"
          >
            <div v-if="(showType === '2' ? isEdit : true) && form.businessLicense.length < 5">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <span>请上传小5M, 格式为JPG、PNG的营业执照图片</span>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-model-item>
      </a-row>
      <div class="formTitle">负责人信息</div>
      <a-form-model-item label="联系人：" :labelCol="{ span: 8 }" :wrapperCol="{ span: 23, offset: 1 }" prop="contacts">
        <a-input
          :value="form.contacts"
          @input="(e) => (form.contacts = nameVerify(e))"
          placeholder="请输入联系人"
          :disabled="showType === '2' && !isEdit"
        />
      </a-form-model-item>
      <a-form-model-item label="联系电话：" :labelCol="{ span: 8 }" :wrapperCol="{ span: 23, offset: 1 }" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入联系电话" :disabled="showType === '2' && !isEdit" />
      </a-form-model-item>
      <a-form-model-item label="邮箱：" :labelCol="{ span: 8 }" :wrapperCol="{ span: 23, offset: 1 }" prop="email">
        <a-input v-model="form.email" placeholder="请输入邮箱" :disabled="showType === '2' && !isEdit" />
      </a-form-model-item>
    </a-form-model>
    <a-form-model :rules="rules" :model="form" ref="ruleForm1">
      <a-form-model-item label="联系人地址：" prop=" ">
        <a-input
          :value="form.contactsAddress"
          style="width: 630px"
          @input="(e) => (form.contactsAddress = forbidEmoji(e))"
          placeholder="请输入联系人地址"
          :disabled="showType === '2' && !isEdit"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import { isvalidPhone, isValidEmail } from "@/utils/validate";
import DSUpload from "@/components/DSComponents/DSUpload";
export default {
  name: "LesseeInfo",
  components: {
    DSUpload
  },
  props: {
    showType: {
      type: String,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    lesseeInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      IMG_API: IMG_API,
      //是否可预览
      previewVisible: false,
      //预览图片
      previewImage: "",
      form: {
        //租户名称
        tenantName: "",
        //租户账号
        username: "",
        //初始密码
        password: "",
        //所属公司
        companyName: "",
        //租户类型
        tenantType: undefined,
        //租户地址
        tenantAddress: "",
        //营业执照图片集合
        businessLicense: [],
        //联系人
        contacts: "",
        //联系电话
        phone: "",
        //邮箱
        email: "",
        //联系人地址
        contactsAddress: "",
        //显示营业执照图片集合
        showBusinessLicense: [],
      },
      rules: {
        tenantName: [
          { required: true, message: "请输入租户名称", trigger: "blur" },
          { max: 20, message: "租户名称最大20位", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入租户账号", trigger: "blur" },
          { max: 30, message: "租户账号最大30位", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入初始密码", trigger: "blur" },
          { min: 8, message: "初始密码最小8位", trigger: "blur" },
          { max: 20, message: "初始密码最大20位", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入所属公司", trigger: "blur" },
          { max: 20, message: "所属公司名称最大20位", trigger: "blur" },
        ],
        tenantType: [{ required: true, message: "请选择租户类型", trigger: "blur" }],
        tenantAddress: [
          { required: true, message: "请输入租户地址", trigger: "blur" },
          { max: 100, message: "租户地址最大100位", trigger: "blur" },
        ],
        businessLicense: [{ required: true, message: "请上传营业执照", trigger: "blur" }],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { max: 8, message: "联系人姓名最大8位", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: isvalidPhone, trigger: ["blur", "change"] },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: isValidEmail, trigger: ["blur", "change"] },
        ],
        contactsAddress: [
          { required: true, message: "请输入联系人地址", trigger: "blur" },
          {
            max: 100,
            message: "联系人地址最大100位",
            trigger: "blur",
          },
        ],
      },
      //租户类型选项
      options: [
        {
          id: "1",
          value: "政府项目",
        },
        {
          id: "2",
          value: "园区项目",
        },
      ],
    };
  },
  watch: {
    lesseeInfo(newVal) {
      if (newVal) {
        this.form = {
          //租户名称
          tenantName: newVal.tenantName,
          //租户账号
          username: newVal.username,
          //初始密码
          password: newVal.password,
          tenantId: newVal.tenantId,
          ...newVal.tenantInfo,
        };
        const businessLicense = JSON.parse(this.form.businessLicense);
        this.form.businessLicense = businessLicense;
        this.form.showBusinessLicense = businessLicense;
      }
    },
  },
  methods: {
    //上传图片前校验
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" ||  file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式");
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("图片最大5M");
      }
      return isJpgOrPng && isLt2M;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url ? file.url : file.response.redirect_uri;
      this.previewVisible = true;
    },
    handleChange({ file, fileList }) {
      let tempArray = [];
      if (file.status === "removed") {
        if (fileList) {
          fileList.forEach((item) => {
            tempArray.push({
              name: item.response ? item.response.name : item.name,
              url: item.response ? item.response.redirect_uri : item.url,
              uid: item.uid,
            });
          });
          this.form.businessLicense = tempArray;
        } else {
          this.form.businessLicense = fileList;
        }
      }
      if (file.status === "done") {
        fileList.forEach((item) => {
          tempArray.push({
            name: item.response.name,
            url: item.response.redirect_uri,
            uid: item.uid,
          });
        });
        this.form.businessLicense = tempArray;
      }
      if (file.status === "uploading" || file.status === "done" || file.status === "removed") {
        this.form.showBusinessLicense = fileList;
        this.form.businessLicense = fileList;
      }
    },

    //下一步
    nextStep() {
      // return {verify: true, form: {}};
      let submitData = {};      
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$refs.ruleForm1.validate((valid1) => {
            if (valid1) {
              submitData.verify = true;
              submitData.form = this.form;
              return true;
            } else {
              this.$message.error("请完善信息");
              submitData.verify = false;
              return false;
            }
          });
        } else {
          //实现校验效果
          this.$refs.ruleForm1.validate(() => {});
          this.$message.error("请完善信息");
          submitData.verify = false;
          return false;
        }
      });
      return submitData;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.formTitle {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  padding-top: 10px;
}
.divExplain {
  position: relative;
  .spanExplain {
    position: absolute;
    left: -70px;
    top: -30px;
    width: 300px;
  }
}
::v-deep .ant-form-inline .ant-form-item {
  width: 200px;
}
</style>
